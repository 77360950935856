import React from 'react';
import * as S from './styles';

import faBatteryFull from '../../icons/bat-charge.svg';
import faWifi from '../../icons/wifi.svg';
import * as M from '../MenuBar/styles'
import faSignal from '../../icons/signal-solid.svg'
//import {useClock} from 'utils/hooks';



const icons = [faSignal,faWifi, faBatteryFull];
//const clock = useClock();


const Window = ({ clock, children, night, ...rest }) => (
  
  <S.Window {...rest}>
    <S.Bar>
      <S.LeftIcons>
        <M.Item key="phone-clock">
          {typeof clock !== 'string' ? <M.Icon clock={clock} /> : <M.Text>{clock}</M.Text>}
        </M.Item>
      </S.LeftIcons>
      <M.Icons>
      
        {icons.map((icon, key) => (
          <M.Item key={key}>
            {typeof icon !== 'string' ? <M.Icon icon={icon} /> : <M.Text>{icon}</M.Text>}
          </M.Item>
        ))}
      </M.Icons>
      <S.Title />
      <S.Empty />
    </S.Bar>
    <S.Children>{children}</S.Children>
  </S.Window>
);

export default Window;
