import React, { useState, useEffect } from 'react';
import * as S from './styles';
import * as A from 'styles/shared-components';
import './styles.css'


const Footer = ({ composeIsOpen, menuBarPose }) => {
  return (
    <S.Footer initialPose="hidden" pose={composeIsOpen ? 'invisible' : menuBarPose}>
      <S.Links>
      {/*
        <A.Link className="footer-link" href="privacy.html">Privacy</A.Link>
        <A.Link className="footer-link" href="disclaimer.html">Disclaimer</A.Link>
        */}
        <A.Link className="footer-link" target="_blank" rel="noopener" href={"https://app.theleaguebot.com/signup" + window.location.search}>
          Signup
        </A.Link>
        <A.Link className="footer-link" target="_blank" rel="noopener" href="https://app.theleaguebot.com/login">
          Login
        </A.Link>
        <A.Link className="footer-link" target="_blank" rel="noopener" href="privacy.html">
          Privacy Policy
        </A.Link>
        <A.Link className="footer-link" href="mailto:support@theLeagueBot.com">
          Support
        </A.Link>
      </S.Links>
    </S.Footer>
  );
};

export default Footer;
