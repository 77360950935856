import styled from 'styled-components';
import flex from 'styles/flex';
import WindowShell from 'components/Window';
import pose from 'react-pose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { applyTheme, getThemeColor, hover, when, whenNot } from 'styles/mixins';
import { breakpoints, isHorizontal, smaller, smallerHeight } from 'styles/responsive';
import { ELEMENTS, zIndexFor } from 'styles/zindex';

export const Text = styled.div(getThemeColor('text'));

export const WindowBox = styled(
  pose.div({
    hidden: {
      opacity: 0
    },
    hiddenCenter: {
      opacity: 0,
      y: ({ y }) => y + 30,
      x: ({ x }) => x
    },
    middle: {
      opacity: 1,
      y: ({ y }) => y + 250,
      x: ({ x }) => x
    },
    normal: { opacity: 1, y: 0, x: 0 }
  })
)({
  maxWidth: 385,
  [isHorizontal]: {
    margin: 'auto',
    width: '100%'
  }
});

export const Home = styled.div({
  ...flex.vertical,
  ...flex.centerVertical,
  flex: 1,
  minHeight: '100vh',
  minWidth: '100vw'
});

export const Subtitle = styled(Text)({
  fontSize: 25,
  fontWeight: 300,
  lineHeight: '40px',
  [smaller(950)]: {
    fontSize: 20
  },
  [smaller(breakpoints.medium)]: {
    fontSize: 18
  },
  [isHorizontal]: {
    fontSize: 18,
    lineHeight: '30px'
  }
});

export const Window = styled(WindowShell)(
  {
    transition: 'all 250ms linear',
    [isHorizontal]: {
      margin: 'auto',
      width: '100%'
    }
  },
  when('hovering', {
    transform: 'scale(1.03)',
    boxShadow: '0 20px 25px 12px rgba(0, 0, 0, 0.22)'
  })
);

export const Title = styled(Text)({
  fontWeight: 500,
  fontSize: 55,
  lineHeight: '50px',
  transition: 'all 250ms linear',
  cursor: 'default',
  marginTop: 15,
});

export const MainSection = styled.div({
  position: 'relative',
  ...flex.vertical,
  width: '100%',
  minHeight: '100vh',
  flex: 1,
  ...zIndexFor(ELEMENTS.MAIN_SECTION)
});

export const Padding = styled.div({
  padding: '60px 100px',
  [smaller(breakpoints.large)]: {
    padding: '30px 50px'
  },
  [smaller(breakpoints.medium)]: {
    padding: '20px 40px'
  },
  [isHorizontal]: {
    padding: '30px 15px'
  }
});

export const Content = styled(Padding)({
  ...flex.horizontal,
  ...zIndexFor(ELEMENTS.CONTENT),
  color: 'white',
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column-reverse',
    ...flex.centerVertical
  }
});

export const TextContent = styled(
  pose.div({
    normal: { opacity: 1 },
    hidden: {
      opacity: 0
    }
  })
)(
  {
    ...flex.vertical,
    [isHorizontal]: {
      ...flex.centerVertical,
      textAlign: 'center'
    }
  },
  whenNot('isAnimationDone', {
    pointerEvents: 'none'
  })
);

export const Platforms = styled.div({
    fontSize: 12
});

export const BodyText = styled(Text)({
  fontSize: 15
});

export const FeatureText = styled(Text)({
  display: "inline-block"
});

export const FeatureTitle = styled(Text)({
  textAlign: "center",
  fontWeight: "bold",
  marginTop: 10,
  marginBottom: 5,
});

export const FeatureImg = styled.img({
  borderRadius: '100%',
  width: 50,
  height: 50,
  maxHeight: 50,
  maxWidth: 50,
  marginRight: 25,
  minWidth: 50,
  minHeight: 50,
  display: "block",
  margin: "0 auto",
});

export const FeatureContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const FeatureGrid = styled.div({
  display: "grid",
  overflow: "hidden",
  gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
  gridColumnGap: "5px",
  gridRowGap: "5px",
  maxWidth: "85%",
  [smaller(950)]: {
    maxWidth: "100%"
  },
  [smaller(350)]: {
    maxWidth: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",

  }
  
  
});

export const FeatureGridItem = styled.div({
  display: "flex",
  padding: "0.5em",
  marginBottom: "20px",
  
});

export const FeatureGridContent = styled.div({
 /* width: "100%",*/
  textAlign: "center",
  lineHeight: 1.4
});

export const FeatureGridAwardContent = styled.div({
  /* width: "100%",*/
   textAlign: "left",
   lineHeight: 1.4,
  
   
 });


export const FeatureIcon = styled(FontAwesomeIcon)(({ color, theme, fill }) => ({
  color: color || theme.colors.featureIcon,

  display: "block",
  margin: "0 auto",
}))

export const PlatformsGrid = styled.div({
  display: "grid",
  overflow: "hidden",
  gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
  gridColumnGap: "5px",
  gridRowGap: "5px",
  //maxWidth: "85%",
  
});

export const PlatformsContainer = styled.div({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: 5,
})

export const PlatformsItem = styled.div({
  display: "flex",
  padding: "0.5em",
  marginBottom: "20px",
});

export const PlatformsIcon = styled(FontAwesomeIcon)({
  display: "inline",
  marginRight: 10,
})

export const PlatformsText = styled(Text)({
  display: "inline",
  fontSize: 15,
})

export const PlatformsTitle = styled(Text)({
  textAlign: "left",
  fontWeight: "bold",
  marginTop: 10,
  marginBottom: 5,
});

export const ComingSoon = styled(Text)({
  textAlign: "right",
  width:"85%",
  
})

export const LogoContainer = styled.div({
  display: "flex",
  justifyContent: "center",
});