
import avatar5 from 'images/avatar-5.png';
import lbAvatar from 'images/leagueBotInitials.png'
import john from 'images/johnAvatarBg.png'
 
export default [
  {
    name: 'LeagueBot',
    username: '',
    message: `    🏆    Weekly Awards     🏆
    *******************************

    🥇      Most Points        🥇
    Antonio Clown with 175.08 points

    💩       Least Points       💩
    WSH Football Fan with 81.52 points

    🤯    Biggest Blowout   🤯
    King Goffrey got blown out by Antonio Clown by 69.08

    😅      Closest Game      😅
    1Kerryon1Personal barely beat Brady Bunch by 3.08
    
    *knock knock*

    Who's there?
    21 days

    21 days who?
    21 days since John Smith's last win`,
    img: lbAvatar,
   // time: 'September 17 - 7:30PM'
  },
  {
    name: 'Carson Miller',
    username: '',
    message: `Guess that's what happens when you sleep in instead of picking up the waiver wire scraps John`,
    img: avatar5
  },
  {
    name: 'John Smith',
    username: '',
    message: `Don't let this distract you from the fact that Carson auto-drafted`,
    img: john
  },
  /*
  {
    name: 'LeagueBot ',
    username: '',
    message: `    🏈    This Week's Matchups    🏈
    **************************************
    (10-3)  Antonio Clown
    (7-6)   WSH Football Fan 

    (7-6)   Keenan & Kelce
    (4-9)   King Goffrey 

    (8-5)   Brady Bunch
    (7-6)   Golden Tate Warriors
    
    (8-5)   1Kerryon1Personal
    (5-8)   Javaris Jamar Javarison-Lamar `,
    img: lbAvatar,
   // time: 'September 17 - 7:30PM'
  },
  */
];
