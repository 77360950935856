import React, { useRef, useState } from 'react';

//icons (imported as svg using babel plugin)

//styles
import * as S from './styles';
import * as A from 'styles/shared-components';

//components
import Messages from 'components/Messages';
import Compose from 'components/Compose';
import ToggleCount from 'components/ToggleCount';
import Background from 'components/Background';
import Footer from 'components/Footer';
import {Button, Tooltip, OverlayTrigger} from "react-bootstrap"

//hooks
import {
  useGoogleAnalytics,
  useHovered,
  useToggleBodyClass,
  useFindElementCenter,
  useMousePosition,
  useCanHover,
  useClock
} from 'utils/hooks';

import useIntroAnimation from './use-intro-animation';

import 'focus-visible';
import DownloadModal from '../DownloadModal';
import { useBoolean } from 'react-hanger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward,
         faFootballBall,
         faClipboardList,
         faSadCry,
         faFlagCheckered,
         faDumbbell,
        } from '@fortawesome/free-solid-svg-icons'

import {faCheckCircle, faCircle,} from '@fortawesome/free-regular-svg-icons'

import './style.css'
import leagueBotLogo from '../../images/LeagueBot_logo_transparent.png'
//env
const { REACT_APP_ANALYTICS_ID, REACT_APP_DOWNLOAD_LINK } = process.env;

const redirectDownload = () => {
  if (window.location.href.includes('get-app')) {
    window.location.replace(REACT_APP_DOWNLOAD_LINK);
  }
};

function Home({ isAnimationDone, night }) {
  redirectDownload();

  const [composeIsOpen, setComposeOpen] = useState(false);
  const [toggleCount, setToggleCount] = useState(0);

  const [text, setText] = useState(
    ``
  );

  // refs
  const contentRef = useRef();
  const messagesWindowRef = useRef();

  //custom hooks
  const isInSizzy = window.__sizzy;
  const { fabPose, menuBarPose, messagesPose, homePose } = useIntroAnimation(!isInSizzy, isAnimationDone);
  const canHover = useCanHover();
  const isHoveringMessages = useHovered();
  const isHoveringCompose = useHovered();
  const windowCenter = useFindElementCenter(messagesWindowRef);
  const { y: mouseY } = useMousePosition(isHoveringCompose.value);
  const clock = useClock();
  const showModal = useBoolean(false);

  // side effects
  useGoogleAnalytics(REACT_APP_ANALYTICS_ID, isAnimationDone.value);
  useToggleBodyClass(isAnimationDone.value, ['scroll', 'no-scroll']);

  // computed
  const isNotHoveringMenuBar = mouseY === null || mouseY >= 25;
  const showComposeWindow = composeIsOpen || (isHoveringCompose.value && isNotHoveringMenuBar);
  const isBig = window.innerWidth > 450;

  // methods
  const onToggleNight = () => {
    night.toggle();
    setToggleCount(toggleCount + 1);
  };

  const tweetProgress = () => {
    setText(
      ``
    );
    setComposeOpen(true);
  };

  const renderComingSoonTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon!
    </Tooltip>
  );

  return (
    <S.Home>
      {showModal.value && <DownloadModal onClose={showModal.setFalse} />}
      <S.MainSection>
        <Background night={night.value} startLoadingLight={isAnimationDone.value} show={isBig} />


        <Compose
          {...isHoveringCompose.bind}
          text={text}
          setText={setText}
          setComposeOpen={setComposeOpen}
          composeIsOpen={composeIsOpen}
          visible={showComposeWindow}
        />

        <S.Content ref={contentRef}>
          <S.WindowBox ref={messagesWindowRef} initialPose="hidden" pose={homePose} {...windowCenter}>
          <S.LogoContainer><img src ={leagueBotLogo} className="leaguebot-logo"></img></S.LogoContainer>
            <S.Window clock={clock} night={night.value} hovering={isHoveringMessages.value}>
              <Messages
                messagesPose={messagesPose}
                fabPose={fabPose}
                night={night.value}
                onToggleNight={onToggleNight}
              />
            </S.Window>
            <A.Space huge/>
            
           <div className="card mb-4 box-shadow ">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Pricing</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$9.99 <small className="text-muted">/ season</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Up to three leagues</li>
              <li>Free one-week trial (No credit card required)</li>
            </ul>
            <div className="text-center">
              <Button className="pricing-signup-button " href={"https://app.theleaguebot.com/signup" + window.location.search} target="_blank" rel="noopener" >Sign up today!</Button>
            </div>
          </div>
          </div>
         
          </S.WindowBox>

          <A.Space huge />

          <S.TextContent isAnimationDone={isAnimationDone.value} pose={homePose}>
            
            <S.Title {...isHoveringMessages.bind}>LeagueBot</S.Title>

            <A.Space huge />
            <S.Subtitle>
              <span>Kick the mid-season slump. </span>
              <br />
              {/*<span>*/}Get your fantasy football league talking again.{/* with <span {...isHoveringMessages.bind}>LeagueBot</span> today. </span>*/}
            </S.Subtitle>

            <A.Space />

            <S.BodyText>LeagueBot joins your league’s group chat and sends messages throughout the week to keep the conversation flowing. </S.BodyText>
            
            <A.Space /><A.Space />

            <S.FeatureGrid >
              <S.FeatureGridItem>
                <S.FeatureGridAwardContent>
                  <S.FeatureIcon icon={faAward} size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Weekly Awards</S.FeatureTitle>
                  <S.FeatureText>🥇&nbsp;&nbsp;Most Points<br></br>💩&nbsp;&nbsp;Least Points<br></br>🤯&nbsp;&nbsp;Biggest Blowout<br></br>😅&nbsp;&nbsp;Closest Game<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</S.FeatureText>
                </S.FeatureGridAwardContent>
              </S.FeatureGridItem>

              <S.FeatureGridItem>
                <S.FeatureGridContent>
                  <S.FeatureIcon icon={faFootballBall}  size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Matchups</S.FeatureTitle>
                  <S.FeatureText>Reminder of who's playing who, sent before Thursday Night Football kicks off</S.FeatureText>
                </S.FeatureGridContent>
              </S.FeatureGridItem>
              
              <S.FeatureGridItem>
                <S.FeatureGridContent>
                <S.FeatureIcon icon={faDumbbell}  size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Power Rankings</S.FeatureTitle>
                  <S.FeatureText>Weekly rankings based on quality of wins, points scored, and margins of victory </S.FeatureText>
                </S.FeatureGridContent>
              </S.FeatureGridItem>

              <S.FeatureGridItem>
                <S.FeatureGridContent>
                <S.FeatureIcon icon={faFlagCheckered}  size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Close Scores</S.FeatureTitle>
                  <S.FeatureText>Keep an eye on matchups that are within 15 points going into Monday Night Football</S.FeatureText>
                </S.FeatureGridContent>
              </S.FeatureGridItem>

              <S.FeatureGridItem>
                <S.FeatureGridContent>
                <S.FeatureIcon icon={faClipboardList}  size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Scoreboard Updates</S.FeatureTitle>
                  <S.FeatureText>Stay up-to-date on how each matchup is going with scoreboard updates after each gameday</S.FeatureText>
                </S.FeatureGridContent>
              </S.FeatureGridItem>

              <S.FeatureGridItem>
                <S.FeatureGridContent>
                <S.FeatureIcon icon={faSadCry}  size="3x"></S.FeatureIcon>
                  <S.FeatureTitle>Trash Talk</S.FeatureTitle>
                  <S.FeatureText>LeagueBot uses in-season data to direct unbiased trash talk at league members who may not be as good at fantasy as they think they are</S.FeatureText>
                </S.FeatureGridContent>
              </S.FeatureGridItem>
            </S.FeatureGrid>
                        

            

            <S.PlatformsGrid>
              <S.FeatureGridItem>
                <S.FeatureGridContent>
                  <S.PlatformsTitle>Supported Messaging Platforms</S.PlatformsTitle>
                  <S.PlatformsContainer>
                    <S.PlatformsIcon icon={faCheckCircle}  size="lg" color="green"></S.PlatformsIcon>
                    <S.PlatformsText>GroupMe</S.PlatformsText>
                  </S.PlatformsContainer>
                  <S.PlatformsContainer>
                    <S.PlatformsIcon icon={faCircle}  size="lg" color="orange"></S.PlatformsIcon>
                    <S.PlatformsText>Slack <i>(Coming Soon)</i></S.PlatformsText>
                  </S.PlatformsContainer>
                </S.FeatureGridContent>
              </S.FeatureGridItem>

              <S.FeatureGridItem>
                <S.FeatureGridContent>
                  <S.PlatformsTitle>Supported League Platforms</S.PlatformsTitle>
                  <S.PlatformsContainer>
                    <S.PlatformsIcon icon={faCheckCircle}  size="lg" color="green"></S.PlatformsIcon>
                    <S.PlatformsText>ESPN public leagues</S.PlatformsText>
                  </S.PlatformsContainer>
                  <S.PlatformsContainer>
                    <S.PlatformsIcon icon={faCheckCircle}  size="lg" color="green"></S.PlatformsIcon>
                    <S.PlatformsText>ESPN private leagues</S.PlatformsText>
                  </S.PlatformsContainer>
                </S.FeatureGridContent>
              </S.FeatureGridItem>
            </S.PlatformsGrid>

            <A.Space />

            <A.Space />

          </S.TextContent>
        </S.Content>
      </S.MainSection>
      <Footer composeIsOpen={composeIsOpen} menuBarPose={menuBarPose} />
    </S.Home>
  );
}

export default Home;
